import React, { Component } from "react";
import axios from "axios";
import ListingHeader from "../Common/ListingHeader";
import Loader from "react-loader-spinner";

class QRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedinurl: "https://www.linkedin.com/in/",
      qrurl: " ",
      showImage: false,
      loading: false,
      notes: "",
      shownotesdiv: false,
      cinchyid: "",
    };
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errormessageforurl: "",
    });
  };
  SubmitNotes = () => {

    var config = {
      method: "get",
      url: `https://cinchy.insurancegig.com/Cinchy/API/CRM/Update_notes_col?%40id=${this.state.cinchyid}&%40notes=${this.state.notes}`,

      headers: {
        "Content-Type": "application/json",
      },
    };
    this.setState({
      loading: true,
    });

    axios(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        this.setState({
          loading: false,
        });
        alert("Data submitted successfully");
      })
      .catch((error) => {
        console.log(error);
      });

  };
  GoBack = () => {
    this.setState({
      showImage: false,
      linkedinurl: "https://www.linkedin.com/in/",
      errormessageforurl: "",
      shownotesdiv: false,
    });
  };
  InsertUrl = () => {
    if (this.state.linkedinurl == "https://www.linkedin.com/in/" || this.state.linkedinurl == "" || (!this.state.linkedinurl.includes("https://www.linkedin.com/in/"))) {
      this.setState({
        errormessageforurl: "Please Enter URL",
      });
      return;
    }

    this.setState({
      loading: true,
    });
    this.InsertProfile();
    this.checkInsideUrl();
  };

  checkInsideUrl = () => {
    var data = JSON.stringify({
      "size": 500,
      "colorDark": "rgb(111,45,128)",
      "logo": "https://marketplacedatadev.s3.amazonaws.com/apidata/logo/IGIG_Icon.png",
      "eye_outer": "eyeOuter2",
      "eye_inner": "eyeInner1",
      "qrData": "pattern0",
      "backgroundColor": "rgb(255,255,255)",
      "transparentBkg": false,
      "qrCategory": "url",
      "text": this.state.linkedinurl,
      "frame": 4,
      "frameText": "InsuranceGIG",
      "frameColor": "#6f2d80"
    });

    var config = {
      method: "post",
      url: "https://www.qrtiger.com/api/qr/static",
      headers: {
        Authorization: "Bearer c5fa1120-2082-11ec-ba83-e71e2337a2dc",
        "Content-Type": "application/json",
        Cookie:
          "connect.sid=s%3AnitioWNiK79k0tDu8L-Al7GeIs-SoeEE.jD8YHjAZHyJqnLvP7FtZhVY9VIqqIu0LatOczlKmkt8",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        JSON.stringify(response.data);

        this.setState({
          qrurl: response.data.url,
          showImage: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            shownotesdiv: true,
          });
        }, 30000);
      })

      .catch((error) => {
        console.log(error);
      });
  }

  verifyProfile = () => {
    if (this.state.linkedinurl == "https://www.linkedin.com/in/" || this.state.linkedinurl == "" || (!this.state.linkedinurl.includes("https://www.linkedin.com/in/"))) {
      this.setState({
        errormessageforurl: "Please Enter URL",
      });
      return;
    }
    window.open(this.state.linkedinurl, "_self");
  };
  InsertProfile = () => {
    var config = {
      method: "get",
      url: `https://cinchy.insurancegig.com/Cinchy/API/CRM/InsertQRCode?%40Email=${"NA"}%40a.com&%40FullName=${"NA"}&%40LinkedInURL=${this.state.linkedinurl
        }&%40Phone=${"NA"}&%40QRCode=Admin`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
     
        this.state.cinchyid = response.data.data[0][0];
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <ListingHeader></ListingHeader>

        {this.state.loading ? (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="TailSpin" color="#6f2d80" height="50" width="50" />
          </div>
        ) : (
          <div class="col-md-12">
            {!this.state.showImage ? (
              <>
                <div class="mb-3">
                  <label for="exampleInputContact" class="form-label">
                    Linkedin URL
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputProfile"
                    name="linkedinurl"
                    onChange={this.onChange}
                    value={this.state.linkedinurl}
                  />

                  {this.state.errormessageforurl != null &&
                    this.state.errormessageforurl.length > 0 ? (
                    <span style={{ color: "red" }}>
                      {this.state.errormessageforurl}
                    </span>
                  ) : null}
                </div>

                <a
                  class="btn  mt-2 mb-2"
                  style={{ backgroundColor: "green", color: "white" }}
                  href="#"
                  onClick={this.verifyProfile}
                >
                  Verify
                </a>

                <button
                  type="submit"
                  class="btn btn-primary float-right mt-2 mb-2"
                  onClick={this.InsertUrl}
                >
                  Submit
                </button>
              </>
            ) : null}

            {this.state.showImage ? (
              <>
                <img src={this.state.qrurl}></img>
                <br />
                <button
                  type="submit"
                  class="btn btn-primary mt-2"
                  onClick={this.GoBack}
                >
                  Go Back
                </button>
              </>
            ) : null}
            {this.state.shownotesdiv ? (
              <div class="mb-3">
                <label for="exampleInputContact" class="form-label">
                  Notes
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  class="form-control"
                  id="exampleInputnotes"
                  name="notes"
                  onChange={this.onChange}
                  value={this.state.notes}
                ></textarea>{" "}
                <button
                  type="submit"
                  class="btn btn-primary mt-2 float-right"
                  onClick={this.SubmitNotes}
                >
                  Submit
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default QRCode;
