import React, { Component } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";

class GetQRProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      LinkedInURL: [],
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      linkId: params.index,
    });
    this.getprofileurl(params.index);
  }

  getprofileurl = (id) => {
    this.setState({ loading: true });
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_Url}/api/qrcode/getprofileurl?QrCodeId=${id}`,
      headers: { }
    };
    axios(config)
      .then((response) => {
        if (response.data != "" || null) {
          window.location.href = response.data[0].linkedInURL;
          this.setState({ loading: false });
        } else {
          this.props.history.push("/profile/" + id);
        }
      });
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="TailSpin" color="#6f2d80" height="50" width="50" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default GetQRProfile;
