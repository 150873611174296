import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
     return (
      promiseInProgress && 
      <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
     }}
    >
      <Loader type="TailSpin" color="#5b0085" height="50" width="50" />
    </div>
    );  
   }
  
ReactDOM.render( 
    <Suspense fallback>
      <App />
      <LoadingIndicator/>
    </Suspense> ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
