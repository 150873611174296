import React, { Component } from "react";
import ListingHeader from "../Common/ListingHeader";
import axios from "axios";
import Loader from "react-loader-spinner";

class QRProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrCodeid: "",
      UserFirstname: "",
      UserLastname: "",
      Useremail: "",
      Usercontact: "",
      Userprofile: "",
      errormessage: "",
      loading: false,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      qrCodeid: params.index,
    });
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleValidation = () => {
    let formIsValid = true;
    if (this.state.UserFirstname == "") {
      // if (this.state.UserFirstname == "") {
      formIsValid = false;
      this.setState({
        errormessage: "Please Enter First Name",
      });
      setTimeout(() => {
        this.setState({
          errormessage: "",
        });
      }, 2000);

      return formIsValid;
    }


    if (this.state.UserLastname == "") {
      formIsValid = false;
      this.setState({
        errormessage: "Please Enter Last Name",
      });
      setTimeout(() => {
        this.setState({
          errormessage: "",
        });
      }, 2000);

      return formIsValid;
    }


    if (this.state.Useremail == "") {
      formIsValid = false;
      this.setState({
        errormessage: "Please Enter Email",
      });
      setTimeout(() => {
        this.setState({
          errormessage: " ",
        });
      }, 1000);
      return formIsValid;
    }

    const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (regex.test(this.state.Useremail) === false) {
      formIsValid = false;
      this.setState({
        errormessage: "Please Enter Valid Email",
      });
      setTimeout(() => {
        this.setState({
          errormessage: " ",
        });
      }, 1000);
      return formIsValid;
    }


    if (this.state.Userprofile == "") {
      formIsValid = false;
      this.setState({
        errormessage: "Please Enter Linkedin Profile Url ",
      });
      setTimeout(() => {
        this.setState({
          errormessage: " ",
        });
      }, 1000);
      return formIsValid;
    }
    return formIsValid;
  };
  InsertProfile = () => {
    if (this.handleValidation()) {
      var data = JSON.stringify({
        "Email":this.state.Useremail,
        "FullName":this.state.UserFirstname + " " + this.state.UserLastname,
        "LinkedInURL":this.state.Userprofile,
        "Phone":this.state.Usercontact,
        "QRCode":this.state.qrCodeid
      })
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_Url}/api/qrcode/insertprofile`,
        headers: {
          "Content-Type": "application/json",
        },
        data : data
      };

      this.setState({
        loading: true,
      });
      axios(config)
        .then((response) => {
          JSON.stringify(response.data);
          this.setState({
            loading: false,
          });
          alert("Confirmed. Your QR code has been created.");
          window.location.href = this.state.Userprofile;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    return (
      <div>
        <ListingHeader></ListingHeader>

        <div class="col-md-12">

        <div class="mb-3">
            <label for="exampleInputProfile" class="form-label">
              Linkedin Profile{" "}
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputProfile"
              name="Userprofile"
              onChange={this.onChange}
              value={this.state.Userprofile}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputName" class="form-label">
              First Name
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputFirstName"
              aria-describedby="emailHelp"
              name="UserFirstname"
              onChange={this.onChange}
              value={this.state.UserFirstname}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputName" class="form-label">
              Last Name
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputLastName"
              aria-describedby="emailHelp"
              name="UserLastname"
              onChange={this.onChange}
              value={this.state.UserLastname}
            />
          </div>



          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              name="Useremail"
              onChange={this.onChange}
              value={this.state.Useremail}
            />
          </div>

          

          {/* <div class="mb-3">
            <label for="exampleInputContact" class="form-label">
              Cell Number (optional)
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputContact"
              name="Usercontact"
              onChange={this.onChange}
              value={this.state.Usercontact}
            />
          </div> */}

          {this.state.errormessage.length > 0 ? (
            <span style={{ color: "red" }}>{this.state.errormessage}</span>
          ) : null}
          <button
            type="submit"
            class="btn btn-primary float-right mt-2"
            onClick={this.InsertProfile}
          >
            Submit
          </button>
          {this.state.loading ? (
            <div
              style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader
                type="TailSpin"
                color="#6f2d80"
                height="50"
                width="50"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default QRProfile;
