import React, { Component } from 'react';

import logo from '../assets/images/New_logo.png';


class ListingHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
         
        }
    }

    componentDidMount = () => {
        
    }
   
    onMarketPlaceClicked = () => {
        this.props.history.push("/");
    }
   
  
    render() {

        const { t } = this.props
        return (
            <div>
                <header className="header-nav sticky-top header-invert">
                    <div className="container-fluid">
                        <div className="navbar navbar-expand-lg navbar-light p-0">
                            <div class="row">
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <a className="logo">
                                        <img src={logo} width="300" height="39.95" alt="InsuranceGIG" />
                                    </a>
                                </div>
                            </div>




                        </div>
                    </div>
                </header>
                <div>


                </div>
            </div>
        );
    }
}

export default ListingHeader;
